<template lang="pug">
  .radio-buttons
    .item(
      v-for="item in items"
      :key="item[valueKey]"
    )
      .big-circle(@click="$emit('change', item[valueKey])")
        .small-circle(v-if="item[valueKey] === value")
      .title {{ item[titleKey] }}
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => new Array()
      },
      value: {
        type: [String, Number]
      },
      valueKey: {
        type: String,
        default: "value"
      },
      titleKey: {
        type: String,
        default: "title"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  $circle-side: 20px
  $small-circle-side: 10px


  .radio-buttons
    .item
      align-items: center
      display: flex

      .big-circle
        align-items: center
        background-color: $default-white
        border: 2px solid $default-purple
        border-radius: $circle-side
        cursor: pointer
        display: flex
        height: $circle-side
        justify-content: center
        margin-right: 20px
        width: $circle-side

        .small-circle
          background-color: $default-purple
          border-radius: $small-circle-side
          height: $small-circle-side
          width: $small-circle-side
          z-index: 1000001

      .title
        font-weight: 600
</style>
